<template>
  <div>
    <h1 class="page-title form-header">Log Complaint</h1>
    <b-overlay :show="busy" rounded="sm">
      <b-form class="form" @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="input-group-1" label-for="input-1">
          <template v-slot:label>
            Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input id="input-1" v-model="name" required placeholder="Enter your name"></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label-for="input-2"
          description="We'll never share your email with anyone else."
        >
          <template v-slot:label>
            Email
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-2"
            v-model="email"
            type="email"
            required
            placeholder="Enter your email"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-3" label-for="input-3">
          <template v-slot:label>
            Mobile Number
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-3"
            type="number"
            v-model="mobile"
            required
            :formatter="limit"
            placeholder="Enter your mobile number"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-issue" label-for="input-issue">
          <template v-slot:label>
            Issue
            <span class="text-danger">*</span>
          </template>
          <b-form-input id="input-issue" type="text" v-model="issue" required placeholder="Enter issue"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-4" label-for="input-4">
          <template v-slot:label>
            Details
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea id="input-4" v-model="details" required placeholder="Enter details"></b-form-textarea>
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Priority
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-status"
            placeholder="Select Priority"
            v-model="priority"
            :options="['LOW', 'MEDIUM', 'HIGH']"
          ></multiselect>

          <b-form-invalid-feedback id="input-status-invalid">Please select priority</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="storeids"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Ids"
            label="text"
            track-by="value"
            :options="stores"
            :multiple="false"
            :taggable="true"
            @tag="addTag"
          ></multiselect>
        </b-form-group>

        <b-form-group id="input-group-5" label-for="input-5">
          <template v-slot:label>
            Upload File
            <span class="text-danger">*</span>
          </template>
          <input type="file" @change="uploadFile($event)" accept="image/*" />
          <!-- <b-form-file id="input-5" v-model="file" required placeholder="Upload file"></b-form-file> -->
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button style="margin: 0 0 0 2%" type="reset" variant="danger">Reset</b-button>
        <b-img
          src="https://www.attabot.in/wp-content/uploads/2020/10/attabot-logo.png"
          fluid
          rounded
          class="icon"
        ></b-img>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import router from '@/router';

export default {
  name: 'ContactUs',
  components: {
    Multiselect
  },
  data() {
    return {
      stores: [],
      name: '',
      email: '',
      mobile: '',
      issue: '',
      details: '',
      priority: 'LOW',
      storeids: [],
      file: [],
      show: true,
      busy: false,
      timeout: null,
      box: ''
    };
  },
  mounted() {
    this.listStore({ router });
  },
  computed: {
    ...mapState('store', ['storeList'])
  },
  methods: {
    ...mapActions('store', ['listStore']),
    ...mapActions('contactUs', ['postOne', 'uploadImage']),
    uploadFile(event) {
      this.file = event.target.files[0]; // eslint-disable-line
      this.uploadImage({ file: this.file });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    limit(e) {
      return e.slice(0, 10);
    },
    onSubmit(evt) {
      evt.preventDefault();
      // Prevent submission if we have any invalid fields
      if (this.hasErrors) {
        alert('Please correct the errors in the form before submitting!');
        return;
      }
      if (this.mobile.length !== 10) {
        Vue.swal({
          title: 'Error!',
          text: 'Please enter valid mobile number, mobile number should be 10 digit',
          type: 'error',
          confirmButtonText: 'Ok',
          icon: 'error'
        });
        return;
      }
      this.showMsgBox();
      this.busy = true;
      this.setTimeout(() => {
        this.busy = false;
      });

      const ticket = {
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        issue: this.issue,
        details: this.details,
        priority: this.priority,
        storeid: this.storeids.value,
        imageName: this.file.name ? this.file.name : ''
      };
      this.postOne({ ticket, router });
    },
    onReset(evt) {
      evt.preventDefault();
      this.name = '';
      this.email = '';
      this.mobile = '';
      this.issue = '';
      this.details = '';
      this.priority = '';
      this.storeids = [];
      this.file = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    showMsgBox() {
      this.box = '';
      setTimeout(
        () =>
          this.$bvModal
            .msgBoxOk('Thanks for contacting us, we will get back to you soon', {
              title: 'Notice',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              footerClass: 'p-2 border-top-0',
              centered: true,
              headerClass: 'p-2 justify-content-center '
            })
            .then(value => {
              this.box = value;
              return router.push(`/complain-tickets`);
            }),
        2000
      );
    },
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
    setTimeout(callback) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.clearTimeout();
        callback();
      }, 2000);
    }
  },
  watch: {
    storeList(newValue) {
      this.stores = newValue;
    }
  }
};
</script>

<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.form {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0 auto;
  width: 60%;
  box-shadow: 0 0 10px rgba(78, 173, 236, 0.3);
  padding: 20px 20px 20px 20px;
}

.icon {
  width: 20%;
  height: auto;
  margin: -8% 0 0 85%;
}

.form-header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

@media only screen and (max-width: 600px) {
  .icon {
    width: 44%;
    height: auto;
    margin: -18% 0 0 68%;
  }
  .form {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .icon {
    width: 36%;
    height: auto;
    margin: -16% 0 0 73%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .icon {
    width: 30%;
    height: auto;
    margin: -13% 0 0 77%;
  }
}

@media only screen and (min-width: 540px) and (max-width: 768px) {
  .icon {
    width: 30%;
    height: auto;
    margin: -13% 0 0 77%;
  }
}
</style>>
